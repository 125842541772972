import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import api from "../../utils/api";

function ReviewPage(props) {
  // props.onLoad(false);
  const [draftReports, setDraftReports] = useState([]);
  const [orderId, setOrderId] = useState();

  const getDraftReports = async () => {
    try {
      const data = await api({
        url: `/zoho/getAllDraftReports`,
        method: "GET",
      });
      
      setDraftReports(data?.data);

      setOrderId(data?.data[0]?.Order_ID?.ID);
    } catch (e) {
      console.log(e, "e");
      throw "data not available";
    }
  };

  useEffect(() => {
    getDraftReports();
  }, []);
  const navigate = useNavigate();
  // reports
  const handleReports = async (orderId) => {
    console.log("orderiddash", orderId);

    // const reports = await axios({
    //   url: GET_SINGLE_REPORT_URL,
    //   method: "POST",
    //   params: { orderId },
    // });
    navigate(`/welcome?orderId=${orderId}`);

    // console.log(reports);

    // var tempPD = JSON.stringify(reports?.data);

    // console.log(tempPD);
    // if (tempPD) {
    //   // draft = JSON.parse(tempPD);
    //   setDraftReports({ ...draft });
    // }
  };

  useEffect(() => { }, [orderId]);

  //filtering out all the draft orders

  // const filtered = draftReports.filter(orders => {
  //   return orders.Status === 'Draft';
  // });
  // console.log(filtered);

  // const getUsers = async () => {
  //  const data =
  // }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filterData = (v) => {
    if (v) {
      setDraftReports([v]);
    } else {
      console.log("v", v);
      return getDraftReports();
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <div
          className="card bdrNone cardTransparent"
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {/* <div>
              <h2>
                <b>All Reports (Draft)</b>
              </h2>
            </div> */}

            <div className="d-flex">
              Showing<span className="ms-2 me-2 text-bold">{draftReports.length}</span>Strand Ids
            </div>
          </Box>

          <div>
            <div>
              <Autocomplete
                disablePortal
                noOptionsText={"No Strand_ID found"}
                id="combo-box-demo"
                options={draftReports}
                onChange={(e, v) => filterData(v)}
                sx={{ width: "100%", mt: "30px" }}
                getOptionLabel={(draftReports) =>
                  draftReports.Order_ID.zc_display_value || ""
                }
                renderInput={(params) => (
                  <TextField {...params} label="Strand_ID" />
                )}
              />
            </div>

            <TableContainer
              sx={{ minWidth: "100%", minHeight: "!00%", marginTop: "30px" }}
              component={Paper}
            >
              <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ background: "#bcc0bc" }}>
                    <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
                      #
                    </TableCell>

                    <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
                      Sample_ID
                    </TableCell>

                    <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {draftReports
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, key) => (
                      <TableRow
                        key={row.ID}
                        className="rowHoverReview"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                        onClick={() => handleReports(row?.Order_ID?.ID)}
                      >
                        <TableCell>{key + 1}</TableCell>

                        <TableCell component="th" scope="row">
                          {row?.Order_ID?.zc_display_value}
                        </TableCell>

                        <TableCell sx={{ color: "orange" }}>
                          {row.Status}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={draftReports.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewPage;
